import React from 'react';
import './Header.scss';
import {Col, Image, Row} from 'react-bootstrap';
import searchMenu from '../../../assets/icons/menu-search.svg';
import view from '../../../assets/icons/view.svg';
import logoBlue from '../../../assets/icons/logo.svg';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Header = ({
                    projectConfigs,
                    controlsToggle,
                    mode,
                    // filter,
                    handleToggle,
                    line
                }) => {
    const {domain, appLogos} = projectConfigs;
    const {t} = useTranslation();
    const search = new URLSearchParams(
        window.location.hash.replace('#', '')
    );
    const {journey, selectedItem} = Object.fromEntries(
        search.entries()
    );
    // const arrowIcon = useCallback(() => {
    //     return (
    //         <>
    //             <i
    //                 style={{
    //                     borderColor:
    //                         mode === 'light' ? primaryColor : secondaryColor,
    //                 }}
    //                 className="arrow left"
    //             />
    //             <span
    //                 style={{
    //                     color: mode === 'light' ? primaryColor : secondaryColor,
    //                 }}
    //                 className="ps-2"
    //             >
    //                 {t('mobile.back')}
    //             </span>
    //         </>
    //     );
    // }, [mode, primaryColor, secondaryColor, t]);

    const reset = () => {
        document.location.href = '/';
    };

    return (
        <header
            className={`max-z-index ${
                mode === 'light' ? 'bgWhite' : 'bgPrimary'
            }`}
        >
            <Row className={'w-100  h-100'}>
                <Col col={4}  className="d-flex align-items-center justify-content-start h-100">
                    {line && !selectedItem && !journey && mode === 'light'  && <div onClick={handleToggle}>
                        <i
                            style={{
                                borderColor:  mode === 'light' ? projectConfigs.primaryColor :projectConfigs.secondaryColor,
                            }}
                            className="arrow left"
                        />
                        <span
                            style={{
                                color:  mode === 'light' ? projectConfigs.primaryColor : projectConfigs.secondaryColor,
                            }}
                            className="ps-2"
                        >
                            {t('back')}
                        </span>
                    </div>
                    }
                    {/*{isReturnButtonExist &&*/}
                    {/*    (!backUrl ? (*/}
                    {/*        <p*/}
                    {/*            className="back-btn-url cursor-pointer"*/}
                    {/*            // onClick={deselect}*/}
                    {/*        >*/}
                    {/*            {arrowIcon()}*/}
                    {/*        </p>*/}
                    {/*    ) : (*/}
                    {/*        <NavLink*/}
                    {/*            className="back-btn-url"*/}
                    {/*            to={backUrl}*/}
                    {/*            // onClick={deselect}*/}
                    {/*        >*/}
                    {/*            {arrowIcon()}*/}
                    {/*        </NavLink>*/}
                    {/*    ))}*/}
                </Col>
                <Col
                    sm={{span: 4, offset: 4}}
                    xs={{span: 4, offset: 4}}
                    className="logo"
                >
                    {appLogos &&
                        appLogos.map((logo) =>
                            mode !== 'light' ? (
                                <img
                                    key={`${logo.image.fileName}-logo`}
                                    onClick={reset}
                                    className="cursor-pointer"
                                    src={`${process.env.REACT_APP_MPR_API_URL}/projects/${domain}/logo/${logo.image.fileName}`}
                                    alt=""
                                />
                            ) : (
                                <Image
                                    key={`${logo.image.fileName}-logo`}
                                    src={logoBlue}
                                    onClick={reset}
                                    alt=""
                                />
                            )
                        )}
                </Col>
                <Col
                    col={4}
                    className="d-flex align-items-center justify-content-end h-100"
                >
                    {mode === 'light' ? (
                        <div
                            onClick={() => {
                                controlsToggle('');
                            }}
                        >
                            <NavLink
                                to={{
                                    pathname: '/fr/line/list',
                                    hash: window.location.hash,
                                }}
                            >
                                <Image src={searchMenu} alt="" style={{filter: 'var(--filter-svg-color)'}}/>
                            </NavLink>
                        </div>
                    ) : (
                        <NavLink
                            to={{
                                pathname: '/fr/line/map',
                                hash: window.location.hash,
                            }}
                        >
                            <Image src={view} alt=""/>
                        </NavLink>
                    )}
                </Col>
            </Row>
        </header>
    );
};
Header.propTypes = {
    projectConfigs: PropTypes.object,
    handleMobilePage: PropTypes.func,
    backUrl: PropTypes.string,
    deselect: PropTypes.func,
    clearCustomLayers: PropTypes.func,
    controlsToggle: PropTypes.func,
    handleToggle: PropTypes.func,
    isReturnButtonExist: PropTypes.bool,
    active: PropTypes.bool,
    mode: PropTypes.string,
    line: PropTypes.string,
    filter: PropTypes.string,
};
Header.defaultProps = {
    isReturnButtonExist: true,
};
export default Header;
