import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Container, Image, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import metro from '../../../../assets/icons/picto_svg/Métro.svg';
import rer from '../../../../assets/icons/picto_svg/RER.svg';
import tram from '../../../../assets/icons/picto_svg/Tramway.svg';
import train from '../../../../assets/icons/picto_svg/Train.svg';
import './PopUp.scss';
import {getHash} from '../../../../utils/urlUtils';

export const PoiPopUp = (props) => {
    useEffect(() => {
        document.getElementById('axeptio_overlay').style.zIndex = '1';
    });
    const {t} = useTranslation();
    const [collapsed, setCollapsed] = useState(true);
    const {items, inPopup, projectConfigs, history, onClose} = props;
    const {primaryColor, poiTitleField, poiSubtitleFields} = projectConfigs;

    // const search = new URLSearchParams(
    //     window.location.hash.replace('#', '')
    // );

    // const {filters} = Object.fromEntries(
    //     search.entries()
    // );

    const lineNumbers = useMemo(() => {
        if (items && items[0]) {

            const lines = items[0]['line-sgp'].map(
                (item) => {
                    return item.replace(/\D/g, '');
                });
            const uniqueArray = [...new Set(lines)];
            uniqueArray.sort((a, b) => a - b);
            return uniqueArray;
        }
    }, [items]);


    // const lineNumber = JSON.parse(filters).lines && JSON.parse(filters).lines[0].match(/[0-9]+/);
    const isCluster = items.length > 1;
    setTimeout(() => setCollapsed(false), 500);
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    const handlePOIDetails = useCallback(
        (mode) => {
            if (history) {
                history.push({
                    pathname: `/fr/${mode}/list/`,
                    hash: window.location.hash,
                    state: {prevPath: history.location.pathname},
                });
            }
        },
        [history]
    );
    if (!items.length || !items[0].guid) {
        return null;
    }
    return (
        <Container
            fluid
            id="MapPopup"
            className={`MapPopup popup-bottom pt-3 pb-3 ${
                isCluster ? 'cluster' : ''
            }  ${!inPopup && collapsed ? 'collapsed' : ''}`}
            style={{background: urlColorMode || primaryColor}}
        >
            {items.map((item) => {
                const {guid} = item;
                const title = item[poiTitleField] || ReactHtmlParser('&nbsp;');
                const upperTitle = item['upperTitle'] || null;
                const subTitle = poiSubtitleFields
                    .map((field) => {
                        return item[field.fieldName];
                    })
                    .join(',');
                return (
                    <React.Fragment key={guid}>
                        <Row>
                            <Col>
                                <p
                                    className={`text-white ${
                                        inPopup ? 'mb-0' : 'text-truncate mb-1 font-size-14'
                                    }`}
                                >
                                    {upperTitle}
                                </p>
                                <h5
                                    className={`mt-0 ${
                                        inPopup ? 'mb-2' : 'mb-1 text-white'
                                    }`}
                                >
                                    {title}
                                </h5>
                                <p
                                    className={`text-white ${
                                        inPopup ? 'mb-2' : 'text-truncate'
                                    }`}
                                >
                                    {subTitle}
                                </p>
                            </Col>
                        </Row>
                        <Row className="sidebar-page">
                            <div className="mb-2 public-transport">
                                {item.properties?.type === 'Gare' &&
                                    <div className="me-3 mb-2">
                                        <Image src={metro} alt=""/>
                                        {item['line-metro'].length > 0 && item['line-metro'].map(
                                            (item, index) => (
                                                <Image
                                                    key={'line-metro-' + index}
                                                    src={require('../../../../assets/icons/picto_svg/' +
                                                        item +
                                                        '.svg')}
                                                    alt=""
                                                />
                                            ))}
                                        {lineNumbers.length > 0 && lineNumbers.map((line) =>
                                            <Image
                                                key={'line-metro-' + line}
                                                src={require('../../../../assets/img/line-numbers/' +
                                                    line +
                                                    '.svg')}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                }
                                {item['line-rer'].length > 0 && (
                                    <div className="me-3 mb-2">
                                        <Image src={rer} alt=""/>
                                        {item['line-rer'].map((item, index) => {
                                            return (
                                                <Image
                                                    key={'line-rer-' + index}
                                                    src={require('../../../../assets/icons/picto_svg/' +
                                                        item +
                                                        '.svg')}
                                                    alt=""
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                                {item['line-tram'].length > 0 && (
                                    <div className="me-3 mb-2">
                                        <Image src={tram} alt=""/>
                                        {item['line-tram'].map(
                                            (item, index) => (
                                                <Image
                                                    key={'line-tram-' + index}
                                                    src={require('../../../../assets/icons/picto_svg/' +
                                                        item +
                                                        '.svg')}
                                                    alt=""
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                                {item['line-transilien'].length > 0 && (
                                    <div className="me-3 mb-2">
                                        <Image src={train} alt=""/>
                                        {item['line-transilien'].map(
                                            (item, index) => (
                                                <Image
                                                    key={
                                                        'line-transilien-' +
                                                        index
                                                    }
                                                    src={require('../../../../assets/icons/picto_svg/' +
                                                        item +
                                                        '.svg')}
                                                    alt=""
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    className="nav-link w-100 cursor-pointer mt-4 btn-calculate"
                                    onClick={() =>
                                        handlePOIDetails('simulator')
                                    }
                                >
                                    {t('popUp.simulator')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="nav-link w-100 cursor-pointer mt-4 btn-calculate"
                                    onClick={() => handlePOIDetails('line')}
                                >
                                    {t('popUp.page')}
                                </Button>
                            </Col>
                        </Row>
                    </React.Fragment>
                );
            })}
            <div className="popup-close" onClick={onClose}/>
        </Container>
    );
};

PoiPopUp.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            guid: PropTypes.string,
            image: PropTypes.object,
            iconType: PropTypes.string,
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            ville: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            'line-sgp': PropTypes.array
        })
    ).isRequired,
    projectConfigs: PropTypes.object,
    history: PropTypes.object,
    onSeeMore: PropTypes.func,
    onClose: PropTypes.func,
    inPopup: PropTypes.bool,
    line: PropTypes.string
};
PoiPopUp.defaultProps = {
    hasSeeMore: true,
    inPopup: false,
};
