import React from 'react';
import PropTypes from 'prop-types';
import './PoiPopup.scss';
import {Button, Col, Container, Row} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import {getHash} from '../../../utils/urlUtils';
import {useMap} from '@mappr/react-lib';

export const PoiPopUp = (props) => {
    const {items, projectConfigs, onClose} = props;
    const {map} = useMap();
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    if (!items.length || !items[0].guid) {
        return null;
    }
    const handleDeselect = () => {
        // Store the original flyTo method
        const originalFlyTo = map.flyTo.bind(map);
        // Store the current zoom and center
        const currentZoom = map.getZoom();
        const currentCenter = map.getCenter();
        // Override flyTo temporarily
        map.flyTo = function () {
        };
        // Call onClose
        onClose();

        setTimeout(() => {
            // Restore the original flyTo method
            map.flyTo = originalFlyTo;

            // Ensure the map stays at the same zoom and center
            map.setZoom(currentZoom);
            map.setCenter(currentCenter);
        }, 100);
    };
    if (items[0].type !== 'Gare')
        return (
            <Container
                id="MapPopup"
                className="MapPopup secondary-layer-popup bg-white rounded"
            >
                {items && (
                    <Row>
                        <Col
                            className={'mb-2'}
                            style={{color: urlColorMode || projectConfigs.primaryColor}}
                        >
                            {items[0].name}
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col>{ReactHtmlParser(items[0].description || '')}</Col>
                </Row>
                {items[0]['url-fiche-gare'] && (
                    <Row className="justify-content-md-center secondary-button">
                        <Col md={6}>
                            <a
                                href={items[0]['url-fiche-gare']}
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <Button
                                    variant={'light'}
                                    style={{
                                        border: `1px solid ${urlColorMode || projectConfigs.primaryColor}`,
                                    }}
                                    className=" w-100  cursor-pointer mt-4 "
                                >
                                    En savoir plus
                                </Button>
                            </a>
                        </Col>
                    </Row>
                )}
                <div className="secondary-close" onClick={handleDeselect}/>
            </Container>
        );

    return null;
};

PoiPopUp.propTypes = {
    items: PropTypes.array.isRequired,
    projectConfigs: PropTypes.object,
    history: PropTypes.object,
    onSeeMore: PropTypes.func,
    onClose: PropTypes.func,
    inPopup: PropTypes.bool,
};
PoiPopUp.defaultProps = {
    hasSeeMore: true,
    inPopup: false,
};
