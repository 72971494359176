import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import collapsable from '../../../assets/icons/collapsable.png';
import close from '../../../assets/icons/close.png';
import './Footer.scss';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {getHash} from '../../../utils/urlUtils';

const Footer = ({toggleMenu, primaryColor}) => {
    const {t} = useTranslation();
    const [isOpenLegalInformation, setIsOpenLegalInformation] = useState(false);
    const legalInformationRef = useRef(null);
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    useEffect(() => {
        function handleClickOutsideOfLegalInformation(event) {
            if (
                legalInformationRef.current &&
                !legalInformationRef.current.contains(event.target)
            ) {
                setIsOpenLegalInformation(false);
            }
        }

        document.addEventListener(
            'mousedown',
            handleClickOutsideOfLegalInformation
        );
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideOfLegalInformation
            );
        };
    }, [legalInformationRef]);
    return (
        <footer style={{backgroundColor: urlColorMode || primaryColor}}>
            <Row className="align-items-center">
                <Col onClick={toggleMenu} className="sidebar-toggle left">
                    <img src={collapsable} width={24} alt=""/>{' '}
                    {t('collapsePanel')}
                </Col>
                <Col>
                    <span
                        style={{
                            cursor: 'pointer',
                            float: 'right',
                            textDecoration: 'underline',
                            textTransform: 'uppercase',
                        }}
                        onClick={() =>
                            setIsOpenLegalInformation(!isOpenLegalInformation)
                        }
                    >
                        {t('legalInformation.title')}
                    </span>
                    {isOpenLegalInformation && (
                        <div
                            ref={legalInformationRef}
                            className="legal-information"
                            style={{border: `1px solid ${primaryColor}`}}
                        >
                            <span
                                className="close"
                                onClick={() => setIsOpenLegalInformation(false)}
                            >
                                <img src={close} width={24} alt=""/>
                            </span>
                            <ul className="p-0">
                                <li>
                                    <a href="#" style={{color: primaryColor}}>
                                        Description textuelle
                                    </a>
                                </li>
                                <li>
                                    <a href="#" style={{color: primaryColor}}>
                                        Aide
                                    </a>
                                </li>
                                <li>
                                    <a href="#" style={{color: primaryColor}}>
                                        Open data
                                    </a>
                                </li>
                                <li>
                                    <a href="#" style={{color: primaryColor}}>
                                        Cookies
                                    </a>
                                </li>
                                <li>
                                    <a href="#" style={{color: primaryColor}}>
                                        Vectuel
                                    </a>
                                </li>
                                <li>
                                    <a href="#" style={{color: primaryColor}}>
                                        Mentions légales
                                    </a>
                                </li>
                            </ul>
                            <div>{t('copyright')}</div>
                        </div>
                    )}
                </Col>
            </Row>
        </footer>
    );
};

Footer.propTypes = {
    toggleMenu: PropTypes.func,
    primaryColor: PropTypes.string,
};
export default Footer;
