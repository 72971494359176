import React, {useContext, useEffect} from 'react';
import {Layers} from '@mappr/react-lib';
import './LayersMenu.scss';
import PropTypes from 'prop-types';
import {AppContext} from '../../../context';
import {getHash} from '../../../utils/urlUtils';
import {Button} from 'react-bootstrap';
import CssFilterConverter from 'css-filter-converter';
import LayerList from './LayerList';

function LayersMenu({openedControls, controlsToggle}) {
    const {configModal, projectConfigs, activeSecondaryLayers} = useContext(AppContext);
    const activeBasemap = getHash('basemap');
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    useEffect(() => {
        if (projectConfigs) {
            const {primaryColor} = projectConfigs;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            const root = document.documentElement;
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [projectConfigs, urlColorMode]);

    const deselectLayers = (activeLayers, toggleLayer) => {
        activeLayers.forEach(layer => {
            if (activeSecondaryLayers.includes(layer) && layer !== 'SGP_Button') { // Custom layer identification
                toggleLayer(
                    layer,
                    'secondary'
                );
            }
        });
    };


    const handleLayerClick = (activeLayers, toggleLayer) => {
        if (activeBasemap !== 'background-111') {
            configModal.handleSave('background-111');
        }
        openedControls === 'layers'
            ? controlsToggle('')
            : controlsToggle('layers');
        deselectLayers(activeLayers, toggleLayer);
    };

    return (
        <Layers group={'secondary'}>
            {(
                {layers, activeLayers},
                {toggleLayer}
            ) => {
                return <div>
                    <Button
                        variant="link"
                        className={`layers-menu-btn text-decoration-none ${
                            openedControls === 'layers' ? 'active' : ''
                        } ${openedControls === 'calendar' ? 'disabled' : ''}`}
                        onClick={() => handleLayerClick(activeLayers, toggleLayer)}
                    >
                        <span className={'me-2'}/>
                        <p>Activités et bénéfices</p>
                    </Button>
                    {openedControls === 'layers' &&
                        <LayerList layers={layers} activeLayers={activeLayers} toggleLayer={toggleLayer}
                                   controlsToggle={controlsToggle} handleLayerClick={handleLayerClick}/>}
                </div>;
            }}
        </Layers>
    );
}

LayersMenu.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
    onToggle: PropTypes.func,
    removeAllFilters: PropTypes.func,
};
export default LayersMenu;
