import React, {useEffect, useState} from 'react';
import './SideBar.scss';
import PropTypes from 'prop-types';
import {Items, SelectedItem} from '@mappr/react-lib';
import {useParams} from 'react-router';
import LineView from './POI/LineView';
import {JourneyList} from './Journey/JourneyList';
import Poi from './POI/Poi';
import NotFound from '../../../components/NotFound/NotFound';
import Calendar from './Calendar/Calendar';
import Tabs from './Tabs/Tabs';
import Layer from './Layer/Layer';
import {getHash} from '../../../utils/urlUtils';
const SideBar = ({
                     isSidebarOpen,
                     toggleMenu,
                     project,
                     openedControl,
                     setOpenedControl,
                     sidebarRef,
                     onToggle,
                     selectedValues,
                     onToggleValues,
                     coreFilters,
                     coreFilterLoading,
                     initialFilters,
                     removeAllFilters,
                     centerOnPoint
                 }) => {

    const {mode} = useParams();

    const [lines, setLines] = useState(undefined);
    // const [calendar, setCalendar] = useState(undefined);
    const {configs} = project;
    const {primaryColor} = configs;

    const search = new URLSearchParams(window.location.hash.replace('#', ''));
    const {filters, selectedItem, journey} = Object.fromEntries(
        search.entries()
    );
    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    useEffect(() => {
        if (filters) {
            setLines(JSON.parse(filters).lines);
            // setCalendar(JSON.parse(filters).calendar);
        } else {
            setLines('');
            // setCalendar('');
        }
    }, [filters]);


    const sidebarContent = (items, selectItem, deselect, item, error) => {
        if (
            (!selectedItem && !journey && !openedControl && !lines) ||
            (!journey && !lines && item && item?.properties.type !== 'Gare')
        ) {
            return (
                <Tabs
                    isSidebarOpen={isSidebarOpen}
                    toggleMenu={toggleMenu}
                    project={project}
                    onToggle={onToggle}
                    selectedValues={selectedValues}
                    onToggleValues={onToggleValues}
                    coreFilters={coreFilters}
                    coreFilterLoading={coreFilterLoading}
                    initialFilters={initialFilters}
                    centerOnPoint={centerOnPoint}
                />
            );
        }
        if (
            selectedItem &&
            !journey &&
            item &&
            item?.properties.type === 'Gare'
        ) {
            return (
                <Poi
                    project={project}
                    toggleMenu={toggleMenu}
                    primaryColor={primaryColor}
                    isSidebarOpen={isSidebarOpen}
                    deselect={deselect}
                    item={item}
                    error={error}
                    line={lines && lines[0]}
                />
            );
        }
        if (!selectedItem) {
            if (openedControl === 'calendar') {
                return (
                    <Calendar
                        project={project}
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                        isSidebarOpen={isSidebarOpen}
                        selectedItem={selectedItem}
                        lines={lines}
                        removeAllFilters={removeAllFilters}
                    />
                );
            } else if (openedControl === 'layers') {
                return (
                    <Layer
                        project={project}
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                        isSidebarOpen={isSidebarOpen}
                        selectedItem={selectedItem}
                        lines={lines}
                        setOpenedControl={setOpenedControl}
                    />
                );
            }
        }

        if (mode === 'line') {
            if ((lines && !selectedItem) || (selectedItem && item && item?.properties.type !== 'Gare')) {
                return (
                    <LineView
                        onToggle={onToggle}
                        project={project}
                        item={items}
                        fiitemslters={coreFilters}
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                        isSidebarOpen={isSidebarOpen}
                        selectItem={selectItem}
                        line={lines[0]}
                        loading={coreFilterLoading}
                    />
                );
            } else if (journey) {
                return <JourneyList project={project}/>;
            } else {
                return null;
            }
        } else if (mode === 'simulator') {
            if (journey) {
                return <JourneyList project={project}/>;
            } else {
                return <NotFound/>;
            }
        } else {
            return <NotFound/>;
        }
    };

    return (
        <Items>
            {({items}, {selectItem}) => {
                return (
                    <div
                        id="sidebar"
                        ref={sidebarRef}
                        style={{background: urlColorMode || primaryColor}}
                        className={`sidebar px-1 ps-0 d-flex flex-column left ${
                            isSidebarOpen ? '' : 'collapsed'
                        }`}
                    >
                        <SelectedItem>
                            {({error, item}, {deselect}) => {
                                const itemsList = items.filter(
                                    (_) => _.properties.type === 'Gare'
                                );
                                return sidebarContent(
                                    itemsList,
                                    selectItem,
                                    deselect,
                                    item,
                                    error
                                );
                            }}
                        </SelectedItem>
                    </div>
                );
            }}
        </Items>
    );
};
SideBar.propTypes = {
    isSidebarOpen: PropTypes.bool,
    openedControl: PropTypes.string,
    project: PropTypes.object,
    onToggle: PropTypes.func,
    selectedValues: PropTypes.object,
    onToggleValues: PropTypes.func,
    sidebarRef: PropTypes.object,
    toggleMenu: PropTypes.func,
    setOpenedControl: PropTypes.func,
    coreFilters: PropTypes.array,
    coreFilterLoading: PropTypes.bool,
    initialFilters: PropTypes.array,
    removeAllFilters:PropTypes.func,
    centerOnPoint:PropTypes.func
};
export default SideBar;
