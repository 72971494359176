import {use3DLayer} from '@mappr/react-lib';
import React, {useState} from 'react';
import {Button, Spinner} from 'react-bootstrap';

function ModelToggleButton() {
    const {visible, toggleVisibility} = use3DLayer();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleClick = () => {
        toggleVisibility();
        setLoading(true);
        setDisabled(true);

        setTimeout(() => {
            setLoading(false);
            setDisabled(false);
        }, 1000);
    };

    return (
        <Button
            active={visible}
            onClick={handleClick}
            variant="primary"
            className="btn-square mb-0 ml-5 d-inline-block align-bottom btn-3d"
            disabled={disabled}
        >
            {loading ? (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                />
            ) : (
                '3D'
            )}
        </Button>
    );
}

export default ModelToggleButton;
