// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

// Create a root container only once
const root = ReactDOM.createRoot(document.getElementById('root'));

// Use the root container you created above, but don't pass the DOM element again
root.render(
        <BrowserRouter>
            <Switch>
                <Route path="/:lang/:mode/" exact component={App}/>
                <Route path="/:lang/:mode/:page" exact component={App}/>
                <Route path="/:lang/" component={App}/>
                <Route path="/" exact component={App}/>
            </Switch>
        </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
