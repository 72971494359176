import React, {useEffect} from 'react';
import {Filters as CoreFilters, useAnalytics} from '@mappr/react-lib';
import PropTypes from 'prop-types';
import DesktopLayout from '../../layouts/DesktopLayout/DesktopLayout';
import MobileLayout from '../../layouts/MobileLayout/MobileLayout';
import Layouts from '../../layouts/Layouts';
import {getDomain} from '../../utils/envUtils';
import i18n from '../../i18n';
import {getHash, setHash} from '../../utils/urlUtils';

const MainContainer = ({project, loading, basemaps, setAppState}) => {
    const d = getDomain();
    const activeBaseMap = sessionStorage.getItem(`${d}-mpr-base-map`);
    const {sendPageView} = useAnalytics();

    useEffect(() => {
        const currentBasemap = project.configs.basemaps.filter(
            (basemap) => basemap.default
        );
        const activeBaseMapAllowed = project.configs.basemaps.filter(
            (basemap) => basemap.id === activeBaseMap
        );

        const urlBasemap = getHash('basemap');

        const urlBasemapAllowed =
            urlBasemap &&
            project.configs.basemaps.filter(
                (basemap) => basemap.id === urlBasemap
            );
        if (!loading && project) {
            const defaultBasemap = currentBasemap[0].id;
            if (!urlBasemap) {
                sessionStorage.setItem(`${d}-mpr-base-map`, defaultBasemap);
            } else if (urlBasemap && urlBasemapAllowed[0]) {
                sessionStorage.setItem(`${d}-mpr-base-map`, urlBasemap);
                setAppState({prop: 'basemap', value: urlBasemap});
            } else if (activeBaseMap && activeBaseMapAllowed[0]) {
                sessionStorage.setItem(`${d}-mpr-base-map`, activeBaseMap);
                setHash('basemap', activeBaseMap);
                setAppState({prop: 'basemap', value: activeBaseMap});
            } else {
                sessionStorage.setItem(`${d}-mpr-base-map`, defaultBasemap);
                setHash('basemap', defaultBasemap);
                setAppState({prop: 'basemap', value: defaultBasemap});
            }
        }
    }, [activeBaseMap, d, loading, project, setAppState]);

    useEffect(() => {
        if (project) {
            document.documentElement.style.setProperty(
                '--primary',
                project.configs.primaryColor
            );
            document.documentElement.style.setProperty(
                '--secondary',
                project.configs.secondaryColor
            );
            sendPageView();
            setAppState({prop: 'projectConfigs', value: project.configs});
            if (project.translations) {
                Object.entries(project.translations).forEach(
                    ([lang, resources]) => {
                        const pt = {};
                        resources.forEach((_) => {
                            pt[_.key] = _.value;
                        });
                        i18n.addResourceBundle(lang, 'project', pt);
                    }
                );
            }
        }
    }, [project, sendPageView, setAppState]);

    return (
        <Layouts>
            {(mode) => (
                <CoreFilters>
                    {/* eslint-disable-next-line no-unused-vars */}
                    {({selectedValues, filters: coreFilters, loading: coreFilterLoading, initialFilters}, {
                        onToggle,
                        onToggleValues,
                        removeAllFilters
                    }) => {
                        return mode === 'mobile' ? (
                            <MobileLayout
                                loading={loading}
                                project={project}
                                basemaps={basemaps}
                                onToggle={onToggle}
                                selectedValues={selectedValues}
                                onToggleValues={onToggleValues}
                                removeAllFilters={removeAllFilters}
                                coreFilters={coreFilters}
                                coreFilterLoading={coreFilterLoading}
                                initialFilters={initialFilters}
                            />
                        ) : (
                            <DesktopLayout
                                loading={loading}
                                project={project}
                                basemaps={basemaps}
                                onToggle={onToggle}
                                selectedValues={selectedValues}
                                onToggleValues={onToggleValues}
                                removeAllFilters={removeAllFilters}
                                coreFilters={coreFilters}
                                coreFilterLoading={coreFilterLoading}
                                initialFilters={initialFilters}
                            />
                        );
                    }}
                </CoreFilters>
            )}
        </Layouts>
    );
};
MainContainer.propTypes = {
    domain: PropTypes.string,
    project: PropTypes.object,
    loading: PropTypes.bool,
    basemaps: PropTypes.array,
    setAppState: PropTypes.func,
};
export default MainContainer;
