import React, {useCallback} from 'react';
import Header from '../../Header/Header';
import {Col, Row} from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import './Layer.scss';
import {Filters as CoreFilters, Layers, useMap} from '@mappr/react-lib';
import SidebarToggleButton from '../../../../components/SidebarToggleButton/SidebarToggleButton';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';

const Layer = ({
                   project,
                   toggleMenu,
                   primaryColor,
                   isSidebarOpen,
                   setOpenedControl,
               }) => {
    const {lang, mode} = useParams();
    const {map} = useMap();
    const onChangeValue = (toggleLayer, onToggle, selectedValues) => {
        toggleLayer('background-111', 'basemap');
        if (selectedValues?.calendar) {
            onToggle('calendar', selectedValues?.calendar[0]);
        }
    };
    const clearCustomLayers = (activeLayers, toggleLayer) => {
        map.getStyle().layers.forEach(layer => {
            if (activeLayers.includes(layer.id)) { // Custom layer identification
                toggleLayer(
                    layer.id,
                    'secondary'
                );
            }
        });
    };
    const handleDeselect = useCallback((removeAllFilters, toggleLayer, activeLayers) => {
        clearCustomLayers(activeLayers, toggleLayer);
        // removeAllFilters();
        setOpenedControl('');
        onChangeValue(toggleLayer);
    }, [setOpenedControl]);
    return (
        <Layers group={'secondary'}>
            {/* eslint-disable-next-line no-empty-pattern */}
            {({activeLayers}, {toggleLayer}) => {
                return isSidebarOpen ? (
                    <>
                        <CoreFilters>
                            {/* eslint-disable-next-line no-empty-pattern */}
                            {({}, {removeAllFilters}) => {
                                return (
                                    <Header
                                        projectConfigs={project.configs}
                                        backUrl={`/${lang}/${mode}/${window.location.hash}`}
                                        deselect={() =>
                                            handleDeselect(
                                                removeAllFilters,
                                                toggleLayer,
                                                activeLayers
                                            )
                                        }
                                    />
                                );
                            }}
                        </CoreFilters>
                        <main>
                            <div className="sidebar-page text-white">
                                <Row className="mb-3">
                                    <Col>
                                        <div className="sidebar-page text-white calendar-sidebar">
                                            <p className="title mt-0">
                                                Vers de nouvelles perspectives
                                                avec le Grand Paris Express
                                            </p>
                                            <div className="description">
                                                Mobilité, culture, éducation,
                                                ville, sport, santé, économie...
                                                le nouveau métro va bénéficier
                                                aux Franciliens dans de nombreux
                                                domaines. Avec cette carte et
                                                les calques qui l’accompagnent,
                                                découvrez toutes les dimensions
                                                de l’utilité du Grand Paris
                                                Express.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </main>
                        <Footer
                            toggleMenu={toggleMenu}
                            primaryColor={primaryColor}
                        />
                    </>
                ) : (
                    <SidebarToggleButton
                        toggleMenu={toggleMenu}
                        isSidebarOpen={isSidebarOpen}
                    />
                );
            }}
        </Layers>
    );
};

Layer.propTypes = {
    project: PropTypes.object,
    toggleMenu: PropTypes.func,
    setOpenedControl: PropTypes.func,
    primaryColor: PropTypes.string,
    isSidebarOpen: PropTypes.bool,
};
export default Layer;
