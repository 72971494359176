export const getHash = (key, json = true) => {
    const hash = window.location.hash.replace('#', '');
    const params = new URLSearchParams(hash);
    if (key)
        return params.get(key)
            ? json
                ? JSON.parse(params.get(key))
                : params.get(key)
            : null;
    else return params.keys();
};

export const setHash = (key, value) => {
    const hash = window.location.hash.replace('#', '');
    const params = new URLSearchParams(hash);

    if (!value) params.delete(key);
    else params.set(key, JSON.stringify(value));

    window.location.hash = params.toString();
};


export const showHideDataLayer = (map, mode, isAllDataLayerVisible = true) => {
    if (isAllDataLayerVisible && map.getLayer('SGP_Button')){
        if (mode==='visible'){
            map.setLayoutProperty('SGP_Button', 'visibility', 'none');
        }else{
            map.setLayoutProperty('SGP_Button', 'visibility', 'visible');
        }
    }

    if (map.getLayer('feature_icon')) {
        map.setLayoutProperty('feature_icon', 'visibility', mode);
    }
    if (map.getLayer('feature_icon_hover')) {
        map.setLayoutProperty('feature_icon_hover', 'visibility', mode);
    }
    if (map.getLayer('data_feature_circle_default')) {
        map.setLayoutProperty('data_feature_circle_default', 'visibility', mode);
    }
    if (map.getLayer('data_feature_circle_label')) {
        map.setLayoutProperty('data_feature_circle_label', 'visibility', mode);
    }
    if (map.getLayer('data_feature_circle')) {
        map.setLayoutProperty('data_feature_circle', 'visibility', mode);
    }
};
