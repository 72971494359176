import React, {useCallback, useContext, useEffect, useMemo, useRef, useState,} from 'react';
import PropTypes from 'prop-types';

import {AppContext} from '../../context';
import Map from './Map/Map';
import SideBar from './Sidebar/SideBar';
import ListLoader from '../../components/loaders/ListLoader/ListLoader';
import AppInfoModal from '../../components/modals/AppInfoModal/AppInfoModal';
import Tutorial from './Tutorial/Tutorial';
import {getDomain} from '../../utils/envUtils';
import {getHash} from '../../utils/urlUtils';
import CssFilterConverter from 'css-filter-converter';

import './DesktopLayout.scss';

function DesktopLayout({
                           loading,
                           project,
                           basemaps,
                           onToggle,
                           selectedValues,
                           onToggleValues,
                           removeAllFilters,
                           coreFilters,
                           coreFilterLoading,
                           initialFilters
                       }) {

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [openAppInfoModal, setAppInfoModal] = useState(false);
    const [openedControl, setOpenedControl] = useState('');
    const [openTutorial, setOpenTutorial] = useState(false);
    const {streetViewModal} = useContext(AppContext);
    const sidebarRef = useRef(null);


    const linesData = useMemo(()=>[
        {name: 'Ligne 14', coordinates: [48.8259, 2.3689], zoom: 10.85},
        {name: 'Ligne 15 Est', coordinates: [48.8788, 2.4328], zoom: 10.85},
        {name: 'Ligne 15 Ouest', coordinates: [48.8742, 2.2572], zoom: 10.95},
        {name: 'Ligne 15 Sud', coordinates: [48.825, 2.3998], zoom: 10.95},
        {name: 'Ligne 16', coordinates: [48.8944, 2.4517], zoom: 10.95},
        {name: 'Ligne 17', coordinates: [48.9628, 2.4587], zoom: 10.87},
        {name: 'Ligne 18', coordinates: [48.7533, 2.1983], zoom: 10.94}
    ],[]);

    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    const search = new URLSearchParams(
        window.location.hash.replace('#', '')
    );
    const {filters} = Object.fromEntries(
        search.entries()
    );

    const toggleSidebar = useCallback(() => {
        if (streetViewModal.open) {
            streetViewModal.handleClose();
        }
        setIsSidebarOpen(!isSidebarOpen);

    }, [isSidebarOpen, streetViewModal]);

    const handleModalClose = () => {
        setAppInfoModal(false);
    };

    const handleOpenTutorial = (isOpen) => {
        setOpenTutorial(isOpen);
    };

    useEffect(() => {
        if (!openedControl && (!filters || Object.keys(JSON.parse(filters)).length === 0)) {
            onToggle('type', 'Gare');
        }
    }, [filters]);


    useEffect(() => {
        if (project) {
            const d = getDomain();
            const isFirstLaunch = !localStorage.getItem(`${d}-mpr-visited`);

            if (
                !!project.configs.appInfoModalEnabled &&
                project.configs?.appInfoModalEmergence === 'onEveryLoad'
            ) {
                setAppInfoModal(true);
            }

            if (isFirstLaunch) {
                localStorage.setItem(`${d}-mpr-visited`, '1');
                setOpenTutorial(!!project.configs.tutorialEnabledOnFirstLoad);

                if (
                    !!project.configs.appInfoModalEnabled &&
                    (project.configs?.appInfoModalEmergence === 'onFirstLoad' ||
                        !project.configs?.appInfoModalEmergence)
                ) {
                    setAppInfoModal(true);
                }
            } else {
                setOpenTutorial(false);
            }
        }
    }, [project]);

    useEffect(() => {
        if (project) {
            const {primaryColor} = project.configs;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            const root = document.documentElement;
            root?.style.setProperty(
                '--btn-background-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }

    }, [project, urlColorMode]);

    useEffect(() => {
        if (streetViewModal.open) {
            setIsSidebarOpen(false);
        }
    }, [streetViewModal]);

    if (loading) {
        return <ListLoader/>;
    }


    const centerOnPoint = (map, lineName) => {
        if (!map || !lineName) return;

        const lineData = linesData.find(line => line.name === lineName);
        if (!lineData) return;

        const onMoveEnd = () => {
            // Remove the event listener after it triggers to avoid repeated calls
            map.off('moveend', onMoveEnd);

            // Call flyTo
            map.flyTo({
                center: [lineData.coordinates[1], lineData.coordinates[0]],
                zoom: lineData.zoom,
                essential: true
            });
        };

        // Add the moveend event listener
        map.on('moveend', onMoveEnd);

    };

    return (
        <div className="container-fluid h-100">
            <section className="row justify-content-center h-100">
                <div className="wrapper p-0">
                    <SideBar
                        project={project}
                        isSidebarOpen={isSidebarOpen}
                        toggleMenu={toggleSidebar}
                        loading={loading}
                        openedControl={openedControl}
                        setOpenedControl={setOpenedControl}
                        sidebarRef={sidebarRef}
                        selectedValues={selectedValues}
                        onToggle={onToggle}
                        onToggleValues={onToggleValues}
                        coreFilters={coreFilters}
                        coreFilterLoading={coreFilterLoading}
                        initialFilters={initialFilters}
                        removeAllFilters={removeAllFilters}
                        centerOnPoint={centerOnPoint}
                    />
                    <Map
                        projectConfigs={project.configs}
                        basemaps={basemaps}
                        isSidebarOpen={isSidebarOpen}
                        loading={loading}
                        openedControl={openedControl}
                        setOpenedControl={setOpenedControl}
                        setSideBarOpen={setIsSidebarOpen}
                        sidebarRef={sidebarRef}
                        onToggle={onToggle}
                        selectedValues={selectedValues}
                        removeAllFilters={removeAllFilters}
                        centerOnPoint={centerOnPoint}
                    />
                </div>
            </section>
            {openAppInfoModal && (
                <AppInfoModal
                    open={openAppInfoModal}
                    onClose={handleModalClose}
                    project={project}
                    appInfoModal={project.configs.appInfoModal}
                />
            )}
            {openTutorial && (
                <Tutorial handleOpenTutorial={handleOpenTutorial}/>
            )}
        </div>
    );
}

DesktopLayout.propTypes = {
    loading: PropTypes.bool,
    project: PropTypes.object,
    basemaps: PropTypes.array,
    onToggle: PropTypes.func,
    onToggleValues: PropTypes.func,
    removeAllFilters: PropTypes.func,
    selectedValues: PropTypes.object,
    coreFilters: PropTypes.array,
    coreFilterLoading: PropTypes.bool,
    initialFilters: PropTypes.array
};

export default DesktopLayout;
