import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Image} from 'react-bootstrap';
import {useMap} from '@mappr/react-lib';

const LineTab = ({name, selectedValues, filters, stats, onToggleValues, centerOnPoint}) => {
    const [sortedFilterValues, setSortedFilterValues] = useState([]);
    const {map} = useMap();

    useEffect(() => {
        const filter = filters?.filter((filter) => filter.name === name).pop();

        const availableValues =
            filter && filter?.stats?.map((item) => item.value);
        let availableItems = [];
        if (stats) {
            availableItems = stats
                .filter((item) => availableValues?.includes(item.value))
                .sort((a, b) => {
                    return a.label_en > b.label_en ? 1 : -1;
                });
        }

        setSortedFilterValues([...availableItems]);
    }, [stats, filters, selectedValues, name]);

    return (
        <ul className="lines">
            {sortedFilterValues.map((filterItem, index) => {
                const itemId = filterItem.value
                    .toLowerCase()
                    .replace(/ /g, '-');
                const inputId = `filter-${name}-check-${itemId}`;
                const number = filterItem.value.match(/\d+/g).map(Number);
                return (
                    <li
                        key={'filter-lines-' + index}
                        onClick={() => {
                            onToggleValues(name, [filterItem.value]);
                            centerOnPoint(map, filterItem.value);
                        }}
                    >
                        <Image
                            className={'me-2'}
                            key={'line-metro-' + index}
                            src={require('../../../assets/img/line-numbers/' +
                                number +
                                '.svg')}
                            alt=""
                        />
                        <label
                            className="custom-control-label cursor-pointer"
                            htmlFor={inputId}
                        >
                            {filterItem.value}
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};
LineTab.propTypes = {
    name: PropTypes.string,
    selectedValues: PropTypes.object,
    filters: PropTypes.array,
    stats: PropTypes.array,
    onToggleValues: PropTypes.func,
    centerOnPoint: PropTypes.func,
};
export default LineTab;
