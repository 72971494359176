import React, { useCallback } from 'react';
import './Poi.scss';
import PropTypes from 'prop-types';
import { POIList } from '../../../../components/LineView/POIList/POIList';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

const LineView = ({ item, selectItem, line, loading, onToggleValues, project }) => {
    const { t } = useTranslation();
    const handleToggle = useCallback(() => {
        onToggleValues('lines', [line]);
    }, [onToggleValues, line]);
    return (
        <>
            <Container
                className="mb-4 flex-row align-items-center back-btn cursor-pointer"
                onClick={handleToggle}
            >
                <Row>
                    <Col>
                        <i
                            style={{
                                borderColor: project.secondaryColor,
                            }}
                            className="arrow left"
                        />
                        <span
                            style={{
                                color: project.secondaryColor,
                            }}
                            className="ps-2"
                        >
                            {t('back')}
                        </span>
                    </Col>
                </Row>
            </Container>

            <POIList
                project={project}
                handleToggle={handleToggle}
                item={item}
                line={line}
                selectItem={selectItem}
                loading={loading}
            />
        </>
    );
};

LineView.propTypes = {
    item: PropTypes.array,
    project: PropTypes.object,
    onToggleValues: PropTypes.func,
    toggleMenu: PropTypes.func,
    primaryColor: PropTypes.string,
    isSidebarOpen: PropTypes.bool,
    selectItem: PropTypes.func,
    line: PropTypes.string,
    loading: PropTypes.bool,
};
export default LineView;
