import React, {useContext} from 'react';
import {Button, Image} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import Header from '../../layouts/DesktopLayout/Header/Header';
import {Filters as CoreFilters} from '@mappr/react-lib';
import {AppContext} from '../../context';
import departure from '../../assets/icons/departure.svg';
import {getHash} from '@mappr/react-lib/lib/utils/urlUtils';

const NotFound = () => {
    const {lang} = useParams();
    const {projectConfigs} = useContext(AppContext);
    const getMap = getHash('map', false);
    const handleDeselect = (removeAllFilters, onToggle) => {
        removeAllFilters();
        onToggle('type', 'Gare');
    };
    return (
        <>
            <CoreFilters>
                {/* eslint-disable-next-line no-empty-pattern */}
                {({}, {removeAllFilters, onToggle}) => {
                    return (
                        <>
                            <Header
                                projectConfigs={projectConfigs}
                                deselect={() =>
                                    handleDeselect(removeAllFilters, onToggle)
                                }
                                isReturnButtonExist={false}
                            />
                            <main>
                                <div>
                                    <div className={'title'}>
                                        Aucun itinéraire trouvé correspondant à
                                        votre recherche
                                    </div>
                                    <Link
                                        onClick={() =>
                                            handleDeselect(removeAllFilters, onToggle)
                                        }
                                        to={`/${lang}/simulator/#map=${getMap}`}
                                    >
                                        <Button className="nav-link w-100 cursor-pointer mt-4 btn-calculate">
                                            <Image
                                                src={departure}
                                                alt=""
                                                width={18}
                                                height={18}
                                                className="me-2"
                                            />
                                            Simuler un nouveau trajet
                                        </Button>
                                    </Link>
                                </div>
                            </main>
                        </>
                    );
                }}
            </CoreFilters>
        </>
    );
};
export default NotFound;
