import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../../context';
import PropTypes from 'prop-types';

const LayerList = ({layers, activeLayers, toggleLayer,handleLayerClick}) => {
    const {t} = useTranslation();
    const {activeSecondaryLayers, setAppState} = useContext(AppContext);
    const activeLayerUpdate = (activeLayers) => {
        setAppState({prop: 'activeSecondaryLayers', value: activeLayers});
    };

    useEffect(() => {
        if (JSON.stringify(activeSecondaryLayers) !== JSON.stringify(activeLayers)) {
            activeLayerUpdate(activeLayers);
        }
    }, [activeSecondaryLayers, activeLayers]);

    return (
        <div className="layers-menu">
            <> {layers.map((section) => {
                if (
                    section.id === '5' ||
                    section.id === '3' ||
                    section.id === '4'
                )
                    return null;
                let sectionHeader = null;
                if (section.id) {
                    sectionHeader = (
                        <div
                            key={`section_${section.id}`}
                            className="layers-menu__section"
                        >
                            {t(
                                `project:map.layers.sections.section_${section.id}`
                            )}
                        </div>
                    );
                }

                let sectionLayers =
                    section.secondaryLayers.map(
                        (aLayer) => {
                            const inputId = `layer-${aLayer.name}-check`;
                            return aLayer.canBeToggled ? (
                                <div
                                    key={aLayer.name}
                                    className="layers-menu__layer custom-checkbox"
                                    onClick={() => {
                                        toggleLayer(
                                            aLayer.name,
                                            'secondary'
                                        );
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        name={inputId}
                                        id={inputId}
                                        checked={activeLayers.includes(
                                            aLayer.name
                                        )}
                                        readOnly
                                    />
                                    <label
                                        htmlFor={
                                            inputId
                                        }
                                    >
                                        {t(
                                            `project:map.layers.${aLayer.name}`
                                        )}
                                    </label>
                                </div>
                            ) : null;
                        }
                    );

                return [
                    sectionHeader,
                    ...sectionLayers,
                ];
            })}
                <div className={'layers-menu-close-btn'} onClick={() => {
                    handleLayerClick(activeLayers, toggleLayer);
                }}>
                    <div
                        className="layers-menu__close"
                    />
                </div>
            </>
        </div>
    );
};


LayerList.propTypes = {
    layers: PropTypes.array,
    activeLayers: PropTypes.array,
    toggleLayer: PropTypes.func,
    controlsToggle: PropTypes.func,
    handleLayerClick:PropTypes.func
};
export default LayerList;
