import React from 'react';
import {Col, Image, Nav, Tab} from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import departure from '../../../../assets/icons/departure.svg';
import {useTranslation} from 'react-i18next';
import SidebarToggleButton from '../../../../components/SidebarToggleButton/SidebarToggleButton';
import PropTypes from 'prop-types';
import Header from '../../Header/Header';
import Line from '../../../../components/LineView/Line';
import Simulator from '../../../../components/SimulatorSearch/Simulator';

const Tabs = ({ isSidebarOpen, toggleMenu, project, selectedValues, onToggleValues, coreFilters,
                  coreFilterLoading,
                  initialFilters,
                  centerOnPoint,
                  onToggle
              }) => {
    const {t} = useTranslation();
    const {lang, mode} = useParams();
    const {primaryColor} = project.configs;
    return (
        <>
            {isSidebarOpen ? (
                <>
                    <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={mode}
                    >
                        <Header
                            projectConfigs={project.configs}
                            isReturnButtonExist={false}
                        />
                        <main>
                            <Nav variant="pills" className="menu-tab">
                                <Col lg={5} md={5} sm={12}>
                                    <Link
                                        to={`/${lang}/line/${window.location.hash}`}
                                    >
                                        <Nav.Link
                                            eventKey="line"
                                            as="button"
                                            className="btn btn-primary w-75"
                                        >
                                            {t('lines.title')}
                                        </Nav.Link>
                                    </Link>
                                </Col>
                                <Col lg={7} md={7} sm={12}>
                                    <Nav.Item>
                                        <Link
                                            to={`/${lang}/simulator/${window.location.hash}`}
                                        >
                                            <Nav.Link
                                                eventKey="simulator"
                                                as="button"
                                                className="btn btn-primary w-100"
                                            >
                                                <Image
                                                    src={departure}
                                                    alt=""
                                                    width={18}
                                                    height={18}
                                                    className="me-2"
                                                />
                                                {t('simulator.tab')}
                                            </Nav.Link>
                                        </Link>
                                    </Nav.Item>
                                </Col>
                            </Nav>
                            <Tab.Content
                                style={{
                                    height:
                                        mode === 'simulator' ? '50%' : 'auto',
                                }}
                            >
                                <Line selectedValues={selectedValues}
                                      onToggleValues={onToggleValues}
                                      filters={coreFilters}
                                      loading={coreFilterLoading}
                                      initialFilters={initialFilters}
                                      centerOnPoint={centerOnPoint}
                                      onToggle={onToggle}/>
                                <Simulator/>
                            </Tab.Content>
                        </main>
                    </Tab.Container>
                    <Footer
                        toggleMenu={toggleMenu}
                        primaryColor={primaryColor}
                    />
                </>
            ) : (
                <SidebarToggleButton
                    toggleMenu={toggleMenu}
                    isSidebarOpen={isSidebarOpen}
                />
            )}
        </>
    );
};

Tabs.propTypes = {
    loading: PropTypes.bool,
    isSidebarOpen: PropTypes.bool,
    toggleMenu: PropTypes.func,
    project: PropTypes.object,
    selectedValues: PropTypes.object,
    onToggle: PropTypes.func,
    onToggleValues: PropTypes.func,
    centerOnPoint: PropTypes.func,
    coreFilters: PropTypes.array,
    coreFilterLoading: PropTypes.bool,
    initialFilters: PropTypes.array
};
export default Tabs;
