import React, {useCallback, useEffect, useState} from 'react';
import {FormLabel, Image, InputGroup, Table} from 'react-bootstrap';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import departure from '../../assets/icons/departure.svg';
import arrival from '../../assets/icons/arrival.svg';
import address from '../../assets/icons/address.svg';
import bus from '../../assets/icons/pubic_transport.svg';
import metro from '../../assets/icons/metro.svg';
import rer from '../../assets/icons/rer.svg';
import train from '../../assets/icons/train.svg';
import tram from '../../assets/icons/tram.svg';
import {useTranslation} from 'react-i18next';

const AutocompleteInput = ({
                               onUpdate,
                               onSelect,
                               suggestions,
                               setItem,
                               item,
                               point,
                               placeholder,
                               onFiltersExpandToggle,
                               label,
                               loading,
                           }) => {
    const [inputValue, setInputValue] = useState(item?.name || '');
    const [timeoutId, setTimeoutId] = useState(undefined);
    useEffect(() => {
        if (item) {
            setInputValue(item.name);
        }
    }, [item]);
    const {t} = useTranslation();
    const handleSearchInputChange = useCallback(
        (inputValue) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setInputValue(inputValue);
            const str = inputValue.split(' ').filter((s) => s.length >= 1);

            setTimeoutId(
                setTimeout(() => {
                    onUpdate(str.length ? str.join(' ') : '');
                }, 500)
            );
        },
        [onUpdate, timeoutId]
    );

    const transportIcons = (commercialModes) => {
        const iconModes = ['train', 'rer', 'tram', 'metro', 'métro', 'tramway', 'bus'];
        const iconsMap = {
            bus: bus,
            others: address,
            metro: metro,
            métro: metro,
            rer: rer,
            train: train,
            tram: tram,
            tramway: tram
        };
        const findMatchingModes = iconModes.filter(icon =>
            commercialModes.some(mode => mode.name.toLowerCase().includes(icon))
        );
        if (findMatchingModes.length === 0) {
            findMatchingModes.push('others');
        }

        const filteredModes = findMatchingModes.reduce((acc, mode) => {
            if ((mode === 'tramway' && acc.includes('tram')) || (mode === 'métro' && acc.includes('metro'))) {
                return acc;
            } else if ((mode === 'tram' && !acc.includes('tramway')) || (mode === 'metro' && !acc.includes('métro'))) {
                acc.push(mode);
            } else if (!acc.includes(mode)) {
                acc.push(mode);
            }
            return acc;
        }, []);

        let first3Element = filteredModes.slice(0, 3);
        return first3Element.map((item) => {
            return <Image key={item} src={iconsMap[item]} alt='' className="icon-image"/>; // Return the SVG for the matched item
        });
    };

    const stateReducer = useCallback((state, changes, suggestions) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownArrowDown:
            case Downshift.stateChangeTypes.keyDownArrowUp: {
                const selectedItem = suggestions[changes.highlightedIndex];
                if (selectedItem) {
                    setInputValue(selectedItem.name);
                }
                return {
                    ...changes,
                    inputValue: selectedItem
                        ? selectedItem.name
                        : state.inputValue,
                };
            }
            default:
                return changes;
        }
    }, []);

    const handleFocus = useCallback(() => {
        onFiltersExpandToggle && onFiltersExpandToggle(false);
    }, [onFiltersExpandToggle]);
    const handleBlur = useCallback(() => {
        onFiltersExpandToggle && onFiltersExpandToggle(true);
    }, [onFiltersExpandToggle]);

    const handleChange = useCallback(
        (selected) => {
            setItem(selected);
        },
        [setItem]
    );

    const handleStateReducer = useCallback(
        (state, changes) =>
            stateReducer(state, changes, ...suggestions, onSelect),
        [onSelect, stateReducer, suggestions]
    );

    const handleItemToString = useCallback(
        (item) => (item ? item.name : ''),
        []
    );

    return (
        <Downshift
            inputValue={inputValue}
            onInputValueChange={handleSearchInputChange}
            onChange={handleChange}
            initialSelectedItem={inputValue}
            itemToString={handleItemToString}
            stateReducer={handleStateReducer}
        >
            {({
                  getInputProps,
                  getItemProps,
                  getMenuProps,
                  isOpen,
                  inputValue,
                  selectedItem,
                  clearSelection,
              }) => {
                return (
                    <div className={'position-relative'}>
                        <FormLabel className={'text-uppercase'}>
                            {label}
                        </FormLabel>
                        <InputGroup
                            className={
                                isOpen &&
                                inputValue.length >= 2 &&
                                suggestions.length > 0
                                    ? 'active'
                                    : selectedItem
                                        ? 'chosen'
                                        : item?.name !== inputValue &&
                                        !selectedItem &&
                                        !suggestions.length &&
                                        inputValue.length > 1 &&
                                        !loading
                                            ? 'error-input'
                                            : 'inactive'
                            }
                        >
                            <div className="input-div">
                                <InputGroup.Text
                                    id="basic-addon1"
                                    className="bg-transparent border-end-0 pe-0"
                                >
                                    <img
                                        src={
                                            point === 'start'
                                                ? departure
                                                : arrival
                                        }
                                        alt=""
                                    />
                                </InputGroup.Text>
                                <input
                                    className={
                                        ' bg-transparent border-start-0  ps-2 pe-3'
                                    }
                                    placeholder={placeholder}
                                    {...getInputProps({
                                        onChange: (e) => {
                                            if (e.target.value === '') {
                                                clearSelection();
                                            }
                                        },
                                    })}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </InputGroup>
                        {item?.name !== inputValue &&
                            !selectedItem &&
                            !suggestions.length &&
                            inputValue.length > 1 &&
                            !loading && (
                                <div className="error-text ps-3 pe-3 mt-1">
                                    {' '}
                                    {t('simulator.error')}
                                </div>
                            )}
                        {isOpen &&
                        inputValue.length >= 2 &&
                        suggestions.length > 0 ? (
                            <>
                                <ul  {...getMenuProps()}
                                     className="custom-autocomplete list-unstyled position-absolute w-100">
                                    <li>
                                        <Table className={'border-0'}>
                                            <tbody>
                                            {isOpen ? (
                                                <>
                                                    {suggestions.map((item, index) => (

                                                        <tr
                                                            key={'from-' + item.name}
                                                            {...getItemProps({
                                                                index:
                                                                    index +
                                                                    '-' +
                                                                    item.name,
                                                                item,
                                                                style: {
                                                                    cursor: 'pointer',
                                                                },
                                                            })}
                                                        >


                                                            <td className={'border-0 d-inline-flex ps-0 float-end'}
                                                                style={{marginTop: '4px'}}>
                                                                {item?.embedded_type ===
                                                                'stop_area' ?
                                                                    transportIcons(item?.stop_area.commercial_modes)
                                                                    :
                                                                    <Image
                                                                        src={address}
                                                                        alt=""
                                                                    />
                                                                }</td>
                                                            <td className={'border-0'}>{item.name}</td>


                                                            {/*<Row>*/}
                                                            {/*    <Col*/}
                                                            {/*        className=" align-items-start justify-content-end"*/}
                                                            {/*        style={{marginTop:'3px',paddingRight:'0px'}}*/}
                                                            {/*    >*/}
                                                            {/*        {item?.embedded_type ===*/}
                                                            {/*        'stop_area' ?*/}
                                                            {/*            transportIcons(item?.stop_area.commercial_modes)*/}
                                                            {/*            :*/}
                                                            {/*            <Image*/}
                                                            {/*                src={address}*/}
                                                            {/*                alt=""*/}
                                                            {/*            />*/}
                                                            {/*        }*/}

                                                            {/*    </Col>*/}
                                                            {/*    <Col*/}
                                                            {/*        className="align-items-start"*/}
                                                            {/*    >*/}
                                                            {/*        {item.name}*/}
                                                            {/*    </Col>*/}
                                                            {/*</Row>*/}
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : null}

                                            </tbody>
                                        </Table>
                                    </li>
                                </ul>
                            </>
                        ) : null}
                    </div>
                );
            }}
        </Downshift>
    );
};

AutocompleteInput.propTypes = {
    onUpdate: PropTypes.func,
    onSelect: PropTypes.func,
    suggestions: PropTypes.array,
    setItem: PropTypes.func,
    item: PropTypes.object,
    point: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onFiltersExpandToggle: PropTypes.func,
    loading: PropTypes.bool,
};
export default AutocompleteInput;
