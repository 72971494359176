import React from 'react';
import {Accordion, Tab} from 'react-bootstrap';
import AfficherTab from './Tabs/AfficherTab';
import SecondaryLayerTab from './Tabs/SecondaryLayerTab';
import LineTab from './Tabs/LineTab';
import {useTranslation} from 'react-i18next';
import ListLoader from '../loaders/ListLoader/ListLoader';
import PropTypes from 'prop-types';

const Line = ({
                  selectedValues, onToggleValues, filters,
                  loading,
                  initialFilters,
                  centerOnPoint,
                  onToggle
              }) => {
    const {t} = useTranslation();

    if (loading) {
        return <ListLoader/>;
    }
    const renderLineContent = () => {
        const line = initialFilters.filter(
            (_) => _.name === 'lines'
        )[0];

        return (
            <LineTab
                selectedValues={selectedValues}
                name={line?.name}
                stats={line?.stats}
                filters={filters}
                onToggleValues={onToggleValues}
                centerOnPoint={centerOnPoint}
            />
        );
    };
    return (
        <Tab.Pane eventKey="line">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('lines.lineTab')}</Accordion.Header>
                    <Accordion.Body>
                        {renderLineContent()}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        {t('lines.worksTab.title')}
                    </Accordion.Header>
                    <Accordion.Body>
                        <AfficherTab
                            selectedValues={selectedValues}
                            onToggleValues={onToggleValues}
                            onToggle={onToggle}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <SecondaryLayerTab/>
        </Tab.Pane>
    );
};
Line.propTypes = {
    selectedValues: PropTypes.object,
    onToggleValues: PropTypes.func,
    onToggle: PropTypes.func,
    centerOnPoint: PropTypes.func,
    filters: PropTypes.array,
    loading: PropTypes.bool,
    initialFilters: PropTypes.array
};
export default Line;
