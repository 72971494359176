import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './MobileLayout.scss';
import Map from './Map/Map';
import SideBar from './Sidebar/SideBar';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {JourneyPopUp} from './Map/PopUp/JourneyPopUp';
import Header from './Header/Header';
import LayersMenu from './Map/LayersMenu/LayersMenu';
import Calendar from './Map/Calendar/Calendar';
import {getDomain} from '../../utils/envUtils';
import AppInfoModal from '../../components/modals/AppInfoModal/AppInfoModal';
import {AppContext} from '../../context';
import StreetViewModal from '../../components/modals/StreetViewModal/StreetViewModal';
import {getHash} from '../../utils/urlUtils';
import CssFilterConverter from 'css-filter-converter';
import {useMap} from '@mappr/react-lib';


function MobileLayout({
                          loading, project, basemaps, onToggle, selectedValues,
                          onToggleValues,
                          removeAllFilters,
                          coreFilters,
                          coreFilterLoading,
                          initialFilters
                      }) {
    const {map} = useMap();
    let lines;
    const {t} = useTranslation();
    const {page, mode} = useParams();
    const [openedControl, setOpenedControl] = useState('');
    const [openAppInfoModal, setAppInfoModal] = useState(false);
    const [maxZoomBackup, setMaxZoomBackup] = useState();
    const search = new URLSearchParams(
        window.location.hash.replace('#', '')
    );
    const {journey, selectedItem} = Object.fromEntries(
        search.entries()
    );

    const {primaryColor} = project.configs;
    const {filters} = Object.fromEntries(
        search.entries()
    );

    const urlColorMode = getHash('color') && `#${getHash('color')}`;
    const {toggleLayer, activeSecondaryLayers, streetViewModal, configModal} = useContext(AppContext);

    useEffect(() => {
        if (project) {
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            const root = document.documentElement;
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [project, urlColorMode, primaryColor]);

    useEffect(() => {
        if (!openedControl && (!filters || Object.keys(JSON.parse(filters)).length === 0)) {
            onToggle('type', 'Gare');
        }
    }, [filters]);

    useEffect(() => {
        if (project) {
            const d = getDomain();
            const isFirstLaunch = !localStorage.getItem(`${d}-mpr-visited`);

            if (
                !!project.configs.appInfoModalEnabled &&
                project.configs?.appInfoModalEmergence === 'onEveryLoad'
            ) {
                setAppInfoModal(true);
            }

            if (isFirstLaunch) {
                localStorage.setItem(`${d}-mpr-visited`, '1');
                if (
                    !!project.configs.appInfoModalEnabled &&
                    (project.configs?.appInfoModalEmergence === 'onFirstLoad' ||
                        !project.configs?.appInfoModalEmergence)
                ) {
                    setAppInfoModal(true);
                }
            }
        }
    }, [project]);


    const clearCustomLayers = () => {
        if (map && activeSecondaryLayers && toggleLayer) {
            map.getStyle().layers.forEach(layer => {
                if (activeSecondaryLayers.includes(layer.id)) { // Custom layer identification
                    toggleLayer(
                        layer.id,
                        'secondary'
                    );
                }
            });
        }
    };

    const handleModalClose = () => {
        setAppInfoModal(false);
    };

    const controlsToggle = useCallback(
        (name) => {
            setOpenedControl(name);
        },
        [setOpenedControl]
    );

    if (loading) {
        return t('common:loading');
    }


    const handleCalendarClick = (deselect, toggleLayer) => {
        removeAllFilters();
        onToggle('type', 'Gare');

        if (openedControl !== 'calendar') {
            controlsToggle('calendar');
            setMaxZoomBackup(map?.getMaxZoom());
            map?.setMaxZoom(10.99);
        } else {
            configModal.handleSave('background-111');
            deselect();
            toggleLayer('background-111', 'basemap');
            location.href = '/';
            controlsToggle('');
            map?.setMaxZoom(maxZoomBackup);
        }

        map.zoomTo(9);
    };

    const clearDataLayers = (toggleLayer) => {
        map.getStyle().layers.forEach(layer => {
            if (activeSecondaryLayers.includes(layer.id) && layer.id !== 'SGP_Button') {
                toggleLayer(
                    layer.id,
                    'secondary'
                );
            }
        });
    };


    const linesData = useMemo(() => [
        {name: 'Ligne 14', coordinates: [48.8259, 2.3689], zoom: 10.85},
        {name: 'Ligne 15 Est', coordinates: [48.8788, 2.4328], zoom: 10.85},
        {name: 'Ligne 15 Ouest', coordinates: [48.8742, 2.2572], zoom: 10.95},
        {name: 'Ligne 15 Sud', coordinates: [48.825, 2.3998], zoom: 10.95},
        {name: 'Ligne 16', coordinates: [48.8944, 2.4517], zoom: 10.95},
        {name: 'Ligne 17', coordinates: [48.9628, 2.4587], zoom: 10.87},
        {name: 'Ligne 18', coordinates: [48.7533, 2.1983], zoom: 10.94}
    ], []);
    const centerOnPoint = (map, lineName) => {
        const lineData = linesData.find(line => line.name === lineName);
        if (lineData && map) {
            map.flyTo({
                center: [lineData.coordinates[1], lineData.coordinates[0]],
                essential: true
            });
        }
    };
    const line = filters && JSON.parse(filters).lines && JSON.parse(filters).lines[0];
    const handleToggle = useCallback(() => {
        onToggleValues('lines', [line]);
    }, [onToggleValues, line]);

    return (
        <div
            className={`mobile-wrapper ${
                selectedItem && !journey ? 'poi' : ''
            }`}
            style={{
                background:
                    page === 'map'
                        ? '#fff'
                        : urlColorMode || project.configs.primaryColor,
            }}
        >
            <Header
                filter={filters}
                projectConfigs={project.configs}
                controlsToggle={controlsToggle}
                handleToggle={handleToggle}
                line={line}
                search={search}
                mode={
                    (page === 'list' &&
                        ((!lines && !journey) ||
                            (selectedItem && !journey))) ||
                    (mode === 'simulator' && !journey)
                        ? 'dark'
                        : 'light'
                }
            />
            {openedControl === 'layers' && page === 'map' && (
                <LayersMenu
                    openedControls={openedControl}
                    controlsToggle={controlsToggle}
                />
            )}
            {openedControl === 'calendar' && page === 'map' && (
                <Calendar
                    openedControls={openedControl}
                    controlsToggle={controlsToggle}
                    selectedValues={selectedValues}
                    onToggle={onToggle}
                    handleCalendarClick={handleCalendarClick}

                />
            )}
            <Map
                projectConfigs={project.configs}
                basemaps={basemaps}
                controlsToggle={controlsToggle}
                position="full"
                active={
                    (!page || page === 'map') && mode === 'line'
                }
                journey={journey}
                onToggle={onToggle}
                selectedValues={selectedValues}
                removeAllFilters={removeAllFilters}
                openedControl={openedControl}
                centerOnPoint={centerOnPoint}
            />

            {journey && page === 'map' && (
                <JourneyPopUp
                    primaryColor={urlColorMode || project.configs.primaryColor}
                />
            )}

            <div
                className={`sidebar ${
                    (!page || page === 'map') &&
                    mode !== 'simulator'
                        ? 'page-hidden'
                        : 'page-active'
                }`}
                style={{background: urlColorMode || project.configs.primaryColor}}
            >
                <SideBar
                    project={project}
                    loading={loading}
                    openedControl={openedControl}
                    setOpenedControl={setOpenedControl}
                    clearCustomLayers={clearCustomLayers}
                    onToggle={onToggle}
                    onToggleValues={onToggleValues}
                    selectedValues={selectedValues}
                    coreFilters={coreFilters}
                    coreFilterLoading={coreFilterLoading}
                    initialFilters={initialFilters}
                    handleCalendarClick={handleCalendarClick}
                    clearDataLayers={clearDataLayers}
                    controlsToggle={controlsToggle}
                />
            </div>
            {openAppInfoModal && (
                <AppInfoModal
                    open={openAppInfoModal}
                    onClose={handleModalClose}
                    project={project}
                    appInfoModal={project.configs.appInfoModal}
                />
            )}
            <StreetViewModal
                open={streetViewModal.open}
                onClose={streetViewModal.handleClose}
                streetView={streetViewModal.medias[0]}
                withSidebar={true}
            />
        </div>
    );
}

MobileLayout.propTypes = {
    loading: PropTypes.bool,
    project: PropTypes.object,
    basemaps: PropTypes.array,
    mode: PropTypes.string,
    onToggle: PropTypes.func,
    onToggleValues: PropTypes.func,
    removeAllFilters: PropTypes.func,
    selectedValues: PropTypes.object,
    coreFilters: PropTypes.array,
    coreFilterLoading: PropTypes.bool,
    initialFilters: PropTypes.array
};

export default MobileLayout;
