export const getDomain = () => {
    // let d;
    // if (process.env.NODE_ENV === 'production') {
    //     const hostname = window.location.host;
    //     d = hostname.substring(0, hostname.indexOf('.'));
    // } else {
    //     d = process.env.REACT_APP_PROJECT_DOMAIN || 'boilerplate';
    // }
    return 'sgp';
};


